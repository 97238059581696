<template>
    <svg viewBox="0 0 346 273" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <linearGradient x1="0%" y1="81.3012106%" x2="100%" y2="18.6987894%" :id="_uid">
                <stop stop-color="var(--color-image-main)" stop-opacity="0.401606206" offset="0%"></stop>
                <stop stop-color="var(--color-image-main)" stop-opacity="0.100442526" offset="100%"></stop>
            </linearGradient>
        </defs>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g fill-rule="nonzero">
                <g transform="translate(0.090554, 0.000000)" :fill="`url(#${ _uid })`">
                    <path d="M326.638513,55.0625872 C373.251743,132.286129 325.648555,199.626631 247.173118,245.380129 C168.69768,291.249752 58.1150815,277.76617 17.103065,221.354486 C-23.9089515,164.942801 12.0562346,82.9514261 90.6496803,37.1979289 C169.00711,-8.55556835 280.025283,-22.1609551 326.638513,55.0625872 Z"></path>
                </g>
                <path d="M310.235845,90.4085227 L108.356151,41.0127841 L108.356151,41.0127841 L82.8555583,34.7943182 C72.9386611,32.3303977 62.9037055,38.4315341 60.4244812,48.2872159 L40.3545702,129.244602 L40.3545702,129.244602 L39.1739872,133.820455 L29.9654397,170.896591 C27.4862154,180.752273 33.625247,190.725284 43.5421443,193.189205 L267.380682,247.864773 C277.297579,250.328693 287.332535,244.227557 289.811759,234.371875 L292.5271,223.577557 L292.5271,223.577557 L300.200889,192.719886 L300.200889,192.719886 L311.29837,147.78267 L311.29837,147.78267 L321.097208,108.125284 C322.986141,100.264205 318.145751,92.2857955 310.235845,90.4085227 Z" fill="#333333" opacity="0.196847098"></path>
                <path d="M275.054471,243.28892 L28.6667984,183.098864 C25.0069911,182.160227 22.7638834,178.523011 23.5902915,174.768466 L59.5980731,29.6318182 C60.5425395,25.9946023 64.2023468,23.7653409 67.9802125,24.5866477 L314.367885,84.8940341 C318.027693,85.8326705 320.2708,89.4698864 319.444392,93.2244318 L283.554669,238.36108 C282.492144,241.998295 278.714279,244.227557 275.054471,243.28892 Z" fill="#EBEBEB"></path>
                <path d="M72.112253,71.6357955 C64.4384634,91.8164773 67.035746,119.62358 87.3417737,129.71392 C160.065687,172.773864 237.511932,168.901989 307.638562,140.508239 L317.437401,100.850852 C319.444392,92.9897727 314.485944,85.0113636 306.576038,83.0167614 L104.696344,33.6210227 C89.5848814,42.1860795 78.7235178,56.8522727 72.112253,71.6357955 Z" fill="#FFFFFF"></path>
                <g transform="translate(29.038141, 91.548900)" fill="var(--color-image-secondary-2)">
                    <polygon transform="translate(133.733265, 77.524683) rotate(13.827795) translate(-133.733265, -77.524683) " points="0.0458327785 61.8128094 267.466529 61.429573 267.420697 93.2365569 -2.27373675e-13 93.6197933"></polygon>
                    <path d="M255.342936,0.502236421 C250.502546,-0.671059034 245.662155,0.26757733 241.766231,2.73149778 C243.891281,6.48604324 244.717689,10.944566 243.537106,15.4030887 C242.474581,19.8616114 239.641182,23.4988273 235.981375,25.8454182 C238.224482,29.8346228 242.120406,32.885191 246.960797,34.0584864 C256.287402,36.2877478 265.732066,30.6559296 267.975174,21.3868955 C270.33634,12.235191 264.669542,2.84882733 255.342936,0.502236421 Z"></path>
                </g>
                <polygon fill="#000000" opacity="0.603526" transform="translate(162.771406, 169.073583) rotate(13.827795) translate(-162.771406, -169.073583) " points="29.0839739 153.361709 296.504671 152.978473 296.458838 184.785457 29.0381412 185.168693"></polygon>
                <g transform="translate(60.052226, 43.513177)" fill="var(--color-image-main)">
                    <path d="M93.8744287,36.9223345 L6.27516981,15.4510277 C4.7404119,15.0990391 3.9140038,13.573755 4.2681787,12.1658005 L6.74740301,2.07545954 C7.10157791,0.550175453 8.63633582,-0.271131365 10.0530354,0.080857271 L97.6522943,21.5521641 C99.1870522,21.9041527 100.01346,23.4294368 99.6592854,24.8373914 L97.1800611,34.9277323 C96.8258862,36.3356868 95.2911283,37.2743232 93.8744287,36.9223345 Z"></path>
                    <path d="M160.45931,69.5399482 L1.08060459,30.5865391 C0.372254788,30.35188 -0.0999784137,29.6479027 0.0180798867,28.9439255 L1.31672119,23.898755 C1.55283779,23.1947777 2.26118759,22.7254595 2.9695374,22.8427891 L162.230185,61.7961982 C162.938534,62.0308573 163.410768,62.7348345 163.292709,63.4388118 L161.994068,68.4839823 C161.87601,69.3052891 161.16766,69.6572777 160.45931,69.5399482 Z"></path>
                    <path d="M210.870204,50.7672209 C208.627097,46.7780164 204.731173,43.7274482 199.890782,42.5541527 C190.564177,40.3248914 181.119513,45.9567095 178.876405,55.2257436 C176.633297,64.4947777 182.300096,73.8811414 191.626701,76.1104027 C196.467092,77.2836982 201.307482,76.3450618 205.203406,73.8811414 C203.078357,70.1265959 202.251948,65.6680732 203.432531,61.2095505 C204.376998,56.7510277 207.210397,53.1138118 210.870204,50.7672209 Z"></path>
                </g>
                <path d="M270.922431,94.2803977 C268.679323,90.2911932 264.783399,87.240625 259.943009,86.0673295 C250.616403,83.8380682 241.171739,89.4698864 238.928631,98.7389205 C236.685524,108.007955 242.352322,117.394318 251.678928,119.62358 C256.519318,120.796875 261.359708,119.858239 265.255632,117.394318 C263.130583,113.639773 262.304175,109.18125 263.484758,104.722727 C264.429224,100.264205 267.262624,96.6269886 270.922431,94.2803977 Z" fill="#FFFFFF" opacity="0.204223"></path>
            </g>
        </g>
    </svg>
</template>

<script>
export default {}
</script>

<style lang="scss">

</style>